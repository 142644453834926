<template>
  <div>
    <a-modal
      v-model="isShow"
      width="300px"
      centered
      :mask-closable="false"
      :ok-button-props="{ props: { loading: submitting } }"
      title="身份验证"
      @ok="handleSubmit"
    >
      <p>为了保护你的帐号安全，请验证身份，验证成功后进行下一步操作</p>

      <a-form
        class="iotplt-compact-form"
        :form="form"
        @submit="handleSubmit"
      >
        <a-form-item :label="label">
          <a-row :gutter="8">
            <a-col :span="13">
              <a-input
                v-decorator="['sms_captcha', {
                  normalize: this.$lodash.trim,
                  rules: [
                    { required: true, message: '请输入验证码' }
                  ]
                }]"
              />
            </a-col>
            <a-col :span="11">
              <a-button
                type="primary"
                :block="true"
                class="iotplt-register-form-captcha-button"
                @click="getSmsCaptcha"
                :disabled="disabled"
              >
                {{ btnTitle }}
              </a-button>
            </a-col>
          </a-row>
        </a-form-item>
      </a-form>
    </a-modal>
  </div>
</template>

<script>
import { findUserEncryptedPhoneNumber } from '@/api/user'
import { sendAuthenticationSmsCaptcha, verifyAuthenticationSmsCaptcha } from '@/api/captcha'
export default {
  name: 'VerifySmsCaptcha',
  props: {
    visible: {
      type: Boolean,
      required: true
    },
    id: {
      type: Number,
      required: true
    },
    identification: {
      type: String,
      required: true
    }
  },
  data() {
    return {
      form: this.$form.createForm(this, { name: 'verify_sms_captcha' }),
      submitting: false,
      label: '',
      disabled: false,
      btnTitle: '获取验证码'
    }
  },
  created() {
    this.fetchFormData()
  },
  computed: {
    isShow: {
      get() {
        return this.visible
      },
      set(val) {
        this.$emit('update:visible', val)
      }
    }
  },
  methods: {
    getSmsCaptcha(e) {
      e.preventDefault()
      if (this.submitting) {
        this.$warning({
          title: '请勿重复提交',
          content: ''
        })
        return
      }
      this.submitting = true
      sendAuthenticationSmsCaptcha().then((res) => {
        if (res.code === 0) {
          this.validateBtn()
        }
        this.submitting = false
      })
    },

    validateBtn() {
      // 倒计时
      let time = 60
      const timer = setInterval(() => {
        if (time === 0) {
          clearInterval(timer)
          this.disabled = false
          this.btnTitle = '获取验证码'
        } else {
          this.btnTitle = time + '秒后重试'
          this.disabled = true
          time -= 1
        }
      }, 1000)
    },

    fetchFormData() {
      findUserEncryptedPhoneNumber(this.id).then((res) => {
        if (res.code === 0) {
          this.label = `使用手机 +86${res.data.phone_number} 验证`
        }
      })
    },

    handleSubmit(e) {
      e.preventDefault()
      if (this.submitting) {
        this.$warning({
          title: '请勿重复提交',
          content: ''
        })
        return
      }
      this.form.validateFields((err, values) => {
        if (!err) {
          this.submitting = true
          const data = {
            sms_captcha: values.sms_captcha
          }
          verifyAuthenticationSmsCaptcha(data).then((res) => {
            if (res.code === 0) {
              this.isShow = false
              this.$emit('completed', { identification: this.identification, nextVerifyId: res.data.next_verify_id })
            }
            this.submitting = false
          })
        }
      })
    }
  }
}
</script>
